<template>
  <div class="about">
    <TopographyHero
      v-if="topographyHeroData"
      :topography-hero-data="topographyHeroData"
    />
    <HighlightsContainer :highlights-data="highlightsData" />
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from 'vuex'
import TopographyHero from '@/components/Hero/TopographyHero'
import HighlightsContainer from '@/components/Highlights/HighlightsContainer'
import helpers from '@/api/helpers/helpers'

export default {
  name: 'About',
  components: { HighlightsContainer, TopographyHero },
  data() {
    return {
      topographyHeroData: null,
      highlightsData: [],
    }
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
  mounted() {
    this.loadAboutData()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadAboutData() {
      this.setAboutData(helpers.readStoredResponse('view.about'))

      api.views.loadAbout().then((response) => {
        this.setAboutData(response)
      })
    },
    setAboutData(response) {
      if (!response || !response.entry) return

      this.topographyHeroData = response.entry.topographyHero[0] || null
      this.highlightsData = response.entry.highlights

      setTimeout(() => {
        this.setPageLoadingFalse()
      }, 300)
    },
  },
}
</script>
