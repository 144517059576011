<template>
  <div class="topography-hero-container">
    <div class="container">
      <h1>{{ topographyHeroData.titleText }}</h1>
      <div class="description" v-html="topographyHeroData.description"></div>

      <AnchorLink
        v-if="topographyHeroData.buttonLink"
        class="button-link"
        type="primaryButton"
        :anchor-link="topographyHeroData.buttonLink"
      >
        {{ topographyHeroData.buttonTitle || 'Learn More' }}
      </AnchorLink>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'TopographyHero',
  components: { AnchorLink },
  props: {
    topographyHeroData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.topography-hero-container {
  @apply bg-cover bg-center py-20 relative;
  background-image: url('~@/assets/topograph-background.jpg');

  .container {
    @apply flex flex-col items-center;
    h1 {
      @apply text-white uppercase mb-3;
      font-family: 'Inter Regular', sans-serif;
      font-size: 2em;
      letter-spacing: 2px;
    }

    .description {
      @apply text-white mb-14;
      max-width: 750px;
      font-family: 'Inter Regular', sans-serif;
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      h1 {
        font-size: 2.5em;
      }
    }
  }

  @media screen and (min-width: 900px) {
    @apply py-36;

    .container {
      h1 {
        font-size: 3em;
      }
    }
  }

  @media screen and (min-width: 1200px) {
  }
}
</style>
